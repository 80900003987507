import React, { useEffect, useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import Link from '../../../components/Link'
import ModalVideo from '../../../components/ModalVideo'
import BookDemoSection from '../../../components/BookDemoSection'

import section1image1 from '../../../images/white-paper/MyHR-People-Animated.gif'
import section1image2 from '../../../images/white-paper/MyHR-70-Percent-Graph-Animated.gif'
import section1image3 from '../../../images/white-paper/MyHR-Bar-Graph-Animated.gif'
import section5image1 from '../../../images/white-paper/MYHR_Pillarpage_Retention__the_collective.png'

import './white-paper.scss'

import whitePaperIcon from '../../../images/white-paper/MYHR_Pillarpage_Retention_WhitePaper Icon.png'


const video1 = {
  url: 'https://www.youtube.com/embed/SxonuEaspso',
  channel: 'youtube',
}
const video2 = {
  url: 'https://www.youtube.com/embed/0YFZBMnGaK4',
  channel: 'youtube',
}


export default () => {
  return (
    <Layout
      className="WhitePaperPage2"
      title="Customer Story"
    >
      <Hero>
        <span className="iconHeading"><img src={whitePaperIcon} />white paper</span>
        <h1>Employee retention:<br />
            The ultimate employer guide</h1>
            <Link
                className={`px-3 px-md-4`}
                onClick="#"
                type="button"
                color="cyan"
            >
                Download white paper &rarr;
            </Link>
      </Hero>

      <Section className="mainContent">
        <Container>
          <Row>
            <Col lg={4} className="">
                <ul className="leftMenu">
                    <li><a href="#section1">Why employees quit&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a></li>
                    <li><a href="#section2">The value of employee retention&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a></li>
                    <li><a href="#section3">How to improve employee retention&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a></li>
                    <li><a href="#section4">Retaining key people during high company growth&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a></li>
                    <li><a href="#section5">Look at your range of benefits and perks&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a></li>
                </ul>
            </Col>
            <Col lg={8} className="">
                <p className="introParagraph">
                    The success of any business often depends on its ability to retain good employees.
                </p>
                <p>
                    Whether it's a small company with just a few staff or a large organisation with multiple offices, businesses 
                    function best when they have a team of talented people focussed on performing not only individually, 
                    but combining as a team to help the company reach its goals.
                </p>
                <p>
                    In the modern job market, skilled professionals are in high demand. Gone are the days of employees staying 
                    in one job, with one company, for most of their working life.
                </p>
                <p>
                    Instead, talented people are willing to trade long-term stability for personal development, career advancement, 
                    better pay and benefits, more flexibility or better work-life balance, or to find a more compatible company 
                    culture and mission. That means that businesses that fail to look after their team members, and meet their 
                    expectations, risk losing them to another that will.
                </p>
                <p>
                    Companies shouldn't wait until they have a problem with high employee turnover before they implement staff 
                    retention strategies. Every company that seeks to thrive should build into its basic operation a range of 
                    measures designed to keep employees happy and motivated, so they stay with the business for as long as possible.
                </p>
                <Row className="section1images">
                    <Col lg={4} className="">
                        <img src={section1image1} />
                    </Col>
                    <Col lg={4} className="">
                        <img src={section1image2} />
                    </Col>
                    <Col lg={4} className="">
                        <img src={section1image3} />
                    </Col>
                </Row>
                <Row className="section1imagesText">
                    <Col lg={4} className="">
                        <p>One employee in five intends to switch to a new employer in the next year</p>
                    </Col>
                    <Col lg={4} className="">
                        <p>70 per cent of NZ organisations say skills shortages are impacting their current effective operation</p>
                    </Col>
                    <Col lg={4} className="">
                        <p>Replacing a long term staff member will cost your business triple their salary</p>
                    </Col>
                </Row>
                <Row className="section1imagesTextSource">
                    <Col lg={4} className="">
                        <p>Source: https://www.pwc.com/gx/ en/about/pwc-asia-pacific/hopes-an d-fears-2022.html?gclid=EAIaIQobCh MIrMOc7qvo-gIVnoNLBR348QD_EA MYASAAEgI0UPD_BwE</p>
                    </Col>
                    <Col lg={4} className="">
                        <p>Source: https://www.hays.net.nz/ employer-insights/recruitment- information/bridging-the-skills-gap</p>
                    </Col>
                    <Col lg={4} className="">
                        <p>According to www.hrinz.org.nz/ site/resources/knowledge_base/q-z /turnover_and_retention_.aspx</p>
                    </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="mainContent" id="section1">
        <Container>
          <Row>
            <Col lg={4} className="leftColumn">
                <h4>
                  It may come as a relief to dismiss an employee who wasn't performing, was disruptive, or had a bad attitude, 
                  but it hurts to lose a star performer who chooses to go.
                </h4>
            </Col>
            <Col lg={8} className="">
                <h2>
                    Why employees quit
                </h2>
                <p>
                    After the time and investment in finding the right person for the role and developing their on-the-job skills, it's back to drafting the job vacancy ad, while their expertise and institutional knowledge heads elsewhere.
                </p>
                <p>
                    It may not reflect badly on the company if the person's reason for leaving was personal, such as to raise children or move to another part of the country, or they decided to change careers or further their education.
                </p>
                <p>
                    It does very little for the business' long-term prospects, however, if it is losing people because of factors it can control, such as the relationship between workers and management, the company culture, or staff feeling overworked and unappreciated.
                </p>
                <p>
                    Talented people want to apply their skills, and expect to be recognised and rewarded for it. They want to be trusted and given opportunities to develop their expertise and experience by doing challenging, meaningful work that helps the company achieve its goals.
                </p>
                <p>
                    If they are given reason to feel the company doesn't care about them, their ideas, or their career, they will have little reason to care about the company. If employees become unmotivated and disengaged, who could blame them for finding another position somewhere else?
                </p>
                <p>&nbsp;</p>
                <Button
                    color="transparent"
                    href="https://blog.myhr.works/10-reasons-top-performers-leave-their-jobs"
                    className=""
                >
                    10 reasons top performers leave their jobs&nbsp; <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="mainContent" id="section2">
        <Container>
          <Row>
            <Col lg={4} className="leftColumn">
                <h4>Retaining your employees and reducing staff turnover makes good business sense.</h4>
            </Col>
            <Col lg={8} className="">
                <h2>
                    The value of employee retention
                </h2>
                <p>
                    Losing a worker costs, both in financial terms – estimated to be 3 times the salary of any person who has held a position for 12 months or longer – and the time and effort it takes to recruit a replacement, bring them onboard, and train them to do the job.
                </p>
                <p>
                    After all that, there is no guarantee the replacement will be as adept as the person who left, and in the meantime, there could be a drop in productivity, morale, and customer service as other workers take up the slack. A high rate of employee attrition also damages the wider culture and company reputation.
                </p>
                <ModalVideo {...video1}>
                  {({ toggle }) => (
                    <div
                      className="UntangleHrSection__video"
                      onClick={toggle}
                    >
                      <div className="UntangleHrSection__video-play-icon">
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="icon play-icon"
                        />
                      </div>
                    </div>
                  )}
                </ModalVideo>
                <p>
                    New Zealand has a high rate of employee turnover, exacerbated by the effects of the COVID-19 pandemic and ongoing economic pressures. MyHR’s 2022 Salary and Employment Report found employee turnover in the more than 1,000 companies in MyHR’s NZ client base was 58.2%, a 10% rise from the previous year.
                </p>
                <p>
                    Other 2022 research by recruitment company, <a href="https://www.beyondrecruitment.co.nz/blog/2022/10/aotearoa-business-confidence-and-the-challenges-ahead-insights-from-our-webinar-with-shamubeel-eaqub?locale=en" target="_blank">Beyond Recruitment</a>, found 83% of NZ businesses across multiple sectors reported that retaining talented workers is much harder now than it was 12 months ago. This is despite the fact that most of the companies surveyed had varying types of employee retention initiatives in place.
                </p>
                <p>
                    Skilled people are in short supply and competition for talent is hot. Research into employee turnover has highlighted the need for companies to make concerted, creative efforts to keep their top employees for as long as possible. Taking a broad and strategic approach to boosting retention rates gives you the best chance of developing a stable, loyal workforce, where people feel valued and want to help drive the company’s success. This gives the business the best chance of hitting its targets, while also building an innovative, supportive environment, which in turn makes it more attractive to other highly-skilled workers.
                </p>
                <p>&nbsp;</p>
                <Button
                    color="transparent"
                    href="https://blog.myhr.works/why-employment-retention-strategies-important"
                    className=""
                >
                    Why employment retention strategies are important&nbsp; <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="mainContent" id="section3">
        <Container>
          <Row>
            <Col lg={4} className="leftColumn">
                <h4>No two people are exactly alike. Employee retention strategies work best when they offer a variety of initiatives and benefits, so individuals can choose what works best for them.</h4>
            </Col>
            <Col lg={8} className="">
                <h2>
                    How to improve employee retention
                </h2>
                <p>
                    Start planning before anyone is hired, so you know what the business needs, what you are looking for in a candidate, and how that person will fit into the existing team.
                </p>
                <p>
                    Once a person is selected, they should clearly understand the business' expectations and the company should understand theirs. Establish a solid basis for the employment relationship with a thorough onboarding process, and ensure all the legal and administrative aspects are taken care of.
                </p>
                <p>
                    Open, ongoing communication is key. Working with employees will enable you to understand what matters to them and what their interests and career aspirations are, so you can then develop a plan that will help realise them.
                </p>
                <p>
                    There is no one-size-fits-all to successful employee retention. It’s a mix of many elements, some concrete, some more indefinite. One person might be more driven by rewards such as better pay and benefits, or <a href="https://blog.myhr.works/blog-when-to-promote-an-employee" target="_blank">promotion opportunities</a>. Another may be more motivated by developing and learning new skills, doing meaningful work, or having more flexible working arrangements and a better work-life balance.
                </p>
                <p>
                    You probably won’t be able to give each person everything they hope for, but the process of working with employees should mean you can build an attractive package and ensure they feel valued and supported. People’s circumstances and motivations also change over time, so make regular check-ins a part of your retention efforts.
                </p>
                <p>
                    Don’t overlook the importance of the company culture, as nothing will have employees looking for a quick exit like an unhealthy environment that treats people as chattels, tolerates poor performance, bad behaviour, or bullying.
                </p>
                <ModalVideo {...video2}>
                  {({ toggle }) => (
                    <div
                      className="UntangleHrSection__video"
                      onClick={toggle}
                    >
                      <div className="UntangleHrSection__video-play-icon">
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="icon play-icon"
                        />
                      </div>
                    </div>
                  )}
                </ModalVideo>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="leftColumn noTopMargin">
                <h4>Employee retention is not only the domain of large corporations with HR departments.</h4>
            </Col>
            <Col lg={8} className="">
                <p>
                    Small and medium-sized enterprises (SMEs) can have a <a href="https://blog.myhr.works/how-to-effectively-find-and-retain-employees" target="_blank">real advantage</a> because it’s easier to understand the needs of each of your employees and to tailor a range of measures to meet them, strengthening commitment, morale, and loyalty, and showing people that they matter.
                </p>
                <p>
                    This helps create a positive work environment where people will want to extend themselves and focus their talents on helping the business succeed.
                </p>
                <p>&nbsp;</p>
                <Button
                    color="transparent"
                    href="https://blog.myhr.works/8-effective-retention-strategies"
                    className=""
                >
                    8 effective retention strategies&nbsp; <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="mainContent" id="section4">
        <Container>
          <Row>
            <Col lg={4} className="leftColumn">
                <h4><img src={section5image1} /></h4>
            </Col>
            <Col lg={8} className="">
                <h2>
                    Retaining key people during high company growth
                </h2>
                <p>
                    Gourmet dairy company Epicurean Dairy has grown from a local Kiwi business to a global brand (The Collective) with offices in New Zealand, Australia, and the United Kingdom, and employees spread over various countries.
                </p>
                <p>
                    The company recognised early on that the strength of their business relied on the passion of its people so it puts a lot of emphasis on hiring and retaining talent. They utilise leading-edge HR software and expert guidance to make consistent recruitment and performance decisions, working to provide employees with ongoing development and advancement opportunities.
                </p>
                <p>
                    It’s a combination of promoting and expanding people’s responsibilities while making sure that employees are working in a safe environment and feel comfortable raising their concerns with management.
                </p>
                <p>&nbsp;</p>
                <Button
                    color="transparent"
                    href="https://blog.myhr.works/retaining-staff-when-your-company-has-a-175-growth-rate"
                    className=""
                >
                    Retaining staff when your company has a 175% growth rate&nbsp; <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="mainContent" id="section5">
        <Container>
          <Row>
            <Col lg={4} className="leftColumn">
                <h4>Another important part of employee retention is offering attractive benefits and perks as part of an employment package.</h4>
            </Col>
            <Col lg={8} className="">
                <h2>
                    Look at your range of benefits and perks
                </h2>
                <p>
                    Benefits demonstrate to employees that the company and management cares about them.
                </p>
                <p>
                    While pay raises and performance bonuses are the most traditional way to retain and attract skilled people, other tools such as increased leave options, opportunities to enhance work-life balance, health and wellbeing initiatives, travel subsides, and a range of discounts or free offers can go a long way to making their working lives more comfortable and enjoyable.
                </p>
                <p>[images here]</p>
                <p>
                    But be aware that many employees view perks and benefits as nice-to-haves rather than must-haves. If people don’t like the company culture or have reason to feel disinterested, mistreated, or dispensable, it's unlikely they will want to perform well enough to merit any extra rewards. Then either the business will look to move them on, or they will do so themselves. Others may soon follow.
                </p>
                <p>&nbsp;</p>
                <Button
                    color="transparent"
                    href="https://blog.myhr.works/top-5-employee-benefits-perks"
                    className=""
                >
                    Top 5 employee benefits perks&nbsp; <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </Col>
          </Row>
        </Container>
      </Section>

      <BookDemoSection
        showTrialButton={false}
      />

    </Layout>
  );
}